<template>
    <div>
        <nav class="navbar navbar-dark navbar-expand-lg fixed-top bg-brown flex-md-nowrap" :class="{'shadow': platform == 'mobile'}">
            <template v-if="platform == 'desktop'">
                <a class="navbar-brand" href="/">
                    <img class="align-top" :src="logo" height="30" alt="logo">
                </a>
                <ul class="navbar-nav mr-auto">
                    <template v-if="isLogged">
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:;" @click="view = 1" :class="{'active': view == 1}"><i class="fas fa-fire mr-2"></i>Eventos</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:;" @click="view = 2" :class="{'active': view == 2}" v-if="pipe != 4"><i class="fas fa-print mr-2"></i>Tabela</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:;" @click="view = 3" :class="{'active': view == 3}"><i class="fas fa-receipt mr-2"></i>Apostas</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:;" @click="prebet = true"><i class="far fa-check-double mr-2"></i>Pré-aposta</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :href="service_link" target="_blank"><i class="fas fa-headset mr-2"></i>Atendimento</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="javascript:;" @click="view = 6" :class="{'active': view == 6}"><i class="fas fa-sack-dollar mr-2"></i>Acumuladão</a>
                        </li> -->
                    </template>
                    <template v-else>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:;" @click="view = 1" :class="{'active': view == 1}"><i class="fas fa-fire mr-2"></i>Eventos</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:;" @click="regras = true"><i class="fas fa-book mr-2"></i>Regras</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:;" @click="ticket = true"><i class="fas fa-receipt mr-2"></i>Bilhete</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="javascript:;" @click="view = 6" :class="{'active': view == 6}"><i class="fas fa-sack-dollar mr-2"></i>Acumuladão</a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" :href="service_link" target="_blank"><i class="fas fa-headset mr-2"></i>Atendimento</a>
                        </li>
                    </template>
                </ul>
                <template v-if="!isLogged">
                    <template v-if="cadastro == 1">
                        <a class="btn btn-secondary my-2 my-sm-0 mr-3" href="/register"><i class="fas fa-user-plus mr-2"></i>Criar conta</a>
                    </template>
                    <button class="btn btn-warning my-2 my-sm-0" @click="loginModal = true"><i class="fas fa-sign-in-alt mr-2"></i>Entrar</button>
                </template>
                <template v-else>
                    <div class="dropdown">
                        <button class="btn btn-outline-warning my-2 my-sm-0 dropdown-toggle" id="dropdown-menu-account" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-user-circle mr-2"></i>Conta
                            <div class="dropdown-menu m-50" aria-labelledby="dropdown-menu-account">
                                <h6 class="dropdown-header">
                                    <div class="text-truncate" style="max-width: 120px;">Olá, {{user_name}}</div>
                                </h6>
                                <div class="dropdown-divider"></div>
                                <div class="dropdown-header">
                                    <i class="far fa-dollar-sign mr-2"></i>
                                    <div class="float-right">{{current_credit}}</div>
                                    <template v-if="pipe == 1">
                                        <div class="dropdown-divider"></div>
                                        <i class="far fa-dollar-sign mr-2"></i>
                                        <div class="float-right">{{current_credit_simple}}</div>
                                    </template>
                                </div>
                                <div class="dropdown-divider"></div>
                                <template v-if="pipe == 4">
                                    <a class="dropdown-item" href="javascript:;" @click="view = 7"><i class="fas fa-user mr-2"></i>Minha conta</a>
                                </template>
                                <template v-else>
                                    <a class="dropdown-item" href="javascript:;" @click="view = 4"><i class="fas fa-coins mr-2"></i>Caixa</a>
                                </template>
                                <a class="dropdown-item" href="javascript:;" @click="logout"><i class="fas fa-sign-out-alt mr-2"></i>Sair</a>
                            </div>
                        </button>
                    </div>
                </template>
            </template>
            <template v-else>
                <div class="d-flex align-items-center w-100 justify-content-between p-2">
                    <div>
                        <template v-if="isCordova">
                            <template v-if="isLogged && pipe == 4">
                                <div class="text-warning" @click="view = 7">
                                    <i class="fad fa-user-circle"></i>
                                </div> 
                            </template>
                            <template v-else>
                                <div class="text-warning" @click="listPrinter">
                                    <i class="fas fa-print"></i>
                                </div> 
                            </template>
                        </template>
                        <template v-else>
                            <template v-if="isLogged && pipe == 1">
                                <div class="text-warning" @click="view = 4">
                                    <i class="fas fa-coins"></i>
                                </div> 
                            </template>
                            <template v-else-if="isLogged && pipe == 4">
                                <div class="text-warning" @click="view = 7">
                                    <i class="fad fa-user-circle"></i>
                                </div> 
                            </template>
                            <template v-else>
                                <div class="text-warning" @click="support">
                                    <i class="fas fa-headset"></i>
                                </div> 
                            </template>
                        </template>
                    </div>
                    <div>
                        <a href="/">
                            <img class="align-middle" :src="logo" height="30" alt="logo">
                        </a>
                    </div>
                    <div>
                        <div class="text-warning" @click="sideBarMenu">
                            <i class="fas fa-bars"></i>
                        </div>   
                    </div>
                </div>
            </template>
        </nav>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pipe: 0,
            user_name: '',
            isLogged: false,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    methods: {
        listPrinter() {
            window.location.href = 'betsnet://printer';
            // window.parent.postMessage({
            //     action: 'printer'
            // }, 'file://');
        },
        support() {
            window.location.href = this.service_link;
        },
        sideBarMenu() {

            if (this.sidebar) {
                this.sidebar = false;
            } else {
                this.sidebar = true;
            }
        },
        logout() {
            this.removeItem('x-token').then(() => {
                this.removeItem('auth').then(() => {
                    this.removeItem('firebase:pipe').then(() => {
                        window.location.href = '/';
                    });
                });
            });
        },
        removeItem(key) {
            return Promise.resolve().then(() => {
                window.localStorage.removeItem(key)
            });
        }
    },
    created() {
        
        try {

            const {logged, name, pipe} = JSON.parse(window.localStorage.getItem('auth'));

            this.pipe = pipe;
            this.user_name = name;
            this.isLogged = logged;
        } 
        catch(e) 
        {
            // window.console.log(e.message);
        }
    
        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.dropdown-menu.m-50 {
    margin-left: -50px;
}
.bg-brown {
    background-color: #3b3b3b!important;
}
</style>